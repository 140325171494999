import { useState, useEffect } from 'react'

import { ThemeOptions, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export interface PillarboxThemeOptions extends ThemeOptions {
  togglePrefersDarkMode(): void;
}

export interface PillarboxTheme extends Theme {
  togglePrefersDarkMode: () => void;
}

export const useThemePreference = (): [boolean, () => void] => {
  const prefersDarkModeMediaQuery = useMediaQuery('(prefers-color-scheme: dark)')
  const [prefersDarkMode, setPrefersDarkMode] = useState(prefersDarkModeMediaQuery)

  useEffect(() => {
    setPrefersDarkMode(prefersDarkModeMediaQuery)
  }, [prefersDarkModeMediaQuery])

  return [prefersDarkMode, (): void => setPrefersDarkMode(!prefersDarkMode)]
}
