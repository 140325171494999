import React, { useContext, useEffect } from 'react'
import logo from '../../static/logo.png'
import Button from '@material-ui/core/Button'
import { LogInToGoogle, PassThroughState } from '../LogInToGoogle'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from '../../Auth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      fontWeight: 400,
      color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.info.light
    },
    logo: {
      height: '40vmin',
      pointerEvents: 'none'
    },
    landing: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)

export const Landing = (): JSX.Element => {
  const classes = useStyles()

  const { isAuthenticated } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()

  const LogIn = (): void => LogInToGoogle(location.state as PassThroughState)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/eips')
    }
  }, [isAuthenticated, history])

  return (
    <Box className={classes.landing}>
      <img src={logo} className={classes.logo} alt="logo" />
      <Typography variant="h2" className={classes.title}>Pillarbox</Typography>
      <Button variant="contained" color="primary" onClick={LogIn}>Log in with Google</Button>
    </Box>
  )
}

export default Landing
