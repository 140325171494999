import React from 'react'

import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import Paper from '@material-ui/core/Paper'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'

import Toolbar from '@material-ui/core/Toolbar'
import FilterListIcon from '@material-ui/icons/FilterList'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import Select from '@material-ui/core/Select'

import Typography from '@material-ui/core/Typography'

import { EIPSummary, NATGateway } from '../../eip'
import { toRepIndicator, useEIPTable } from './Data'
import {
  useEIPFilter,
  AutoCompleteOption,
  AddressFilter,
  NatGatewayFilter,
  MTAHostnameFilter,
  SubnetIDFilter,
  NoSubnetsFilter,
  IPRangeFilter,
  IPRepFilter
} from './Filter'
import { useSort } from './Sort'

import { isPartialIP, isIPRange, unusedBits, expandToIPAddr, parseIPRange } from './IPRange'

import CopyToClipboard from '../CopyToClipboard'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import IconButton from '@material-ui/core/IconButton'
import { InputProps } from '@material-ui/core'

const useTableStyles = makeStyles(createStyles({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  searchBar: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '35%'
  },
  ipRepFilterLabel: {
    paddingLeft: '16px',
    paddingRight: '10px'
  }
}))

const useCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.info.light
    },
    icon: {
      padding: theme.spacing(1)
    }
  })
)

interface Column {
  header: string;
  align?: 'left' | 'center' | 'right';
}

export interface Columns {
  address: Column;
  gatewayID: Column;
  mtaHostname: Column;
  subnetID: Column;
  reputation: Column;
}

const columns: Columns = {
  address: { header: 'EIP Address' },
  gatewayID: { header: 'NAT Gateway', align: 'right' },
  mtaHostname: { header: 'MTA hostname' },
  subnetID: { header: 'Subnet ID' },
  reputation: { header: 'Reputation', align: 'center' }
}

interface Cell {
  label: string;
  link?: string;
  externalLink?: string;
  copyable?: boolean;
}

export type EIPTableRow = Map<keyof Columns, Cell | undefined>

const resolveSubnetsCell = (gateway: NATGateway): Cell => {
  if (gateway.subnet_ids === undefined || gateway.subnet_ids.length === 0) {
    return {
      label: 'No subnet',
      copyable: false
    }
  }

  if (gateway.subnet_ids.length > 1) {
    return {
      label: `${gateway.subnet_ids.length} subnets`,
      externalLink: gateway.subnets_link,
      copyable: false
    }
  }

  return {
    label: gateway.subnet_ids[0],
    externalLink: gateway.subnets_link
  }
}

export const tableDataFromSummaries = (summaries: EIPSummary[]): EIPTableRow[] => summaries.map(summary => {
  const row = new Map() as EIPTableRow

  const address: Cell = {
    label: summary.address,
    link: `eips/${summary.address}`,
    externalLink: summary.address_link
  }

  const gatewayID: Cell | undefined = summary.nat_gateway
    ? {
      label: summary.nat_gateway.id,
      externalLink: summary.nat_gateway.link
    }
    : undefined

  const mtaHostname: Cell | undefined = summary.nat_gateway?.mta_hostname
    ? {
      label: summary.nat_gateway.mta_hostname,
      externalLink: summary.nat_gateway.mta_link
    }
    : undefined

  const subnetID: Cell | undefined = summary.nat_gateway ? resolveSubnetsCell(summary.nat_gateway) : undefined

  const reputation: Cell = {
    label: toRepIndicator(summary.listed_status),
    copyable: false
  }

  row.set('address', address)
  row.set('gatewayID', gatewayID)
  row.set('mtaHostname', mtaHostname)
  row.set('subnetID', subnetID)
  row.set('reputation', reputation)

  return row
})

export interface EIPCellProps {
  cell?: Cell;
  align?: 'left' | 'center' | 'right';
  copyable: boolean;
}

export const EIPCell = ({ cell, align, copyable }: EIPCellProps): JSX.Element => {
  const classes = useCellStyles()

  return (
    <TableCell align={align ?? 'left'}>
      {cell &&
        <Typography>
          {cell.link ? <Link href={cell.link} className={classes.link}>{cell.label}</Link> : cell.label}
          {copyable && <CopyToClipboard copyText={cell.label} />}
          {cell.externalLink !== undefined &&
          <Link href={cell.externalLink} className={classes.link} target='_blank' rel='noopener noreferrer'>
            <IconButton aria-label="external link" className={classes.icon} >
              <OpenInNewIcon />
            </IconButton>
          </Link>}
        </Typography>}
    </TableCell>
  )
}

// Combines the type and label into a unique key to ensure the option isn't duplicated.
const addIfNotPresent = (mp: Map<string, AutoCompleteOption>, ...filters: AutoCompleteOption[]): void => {
  filters.forEach(flts => mp.set(flts.type + flts.label, flts))
}

const ipRangeIsApplicable = (fl: IPRangeFilter, summaries: EIPSummary[]): boolean => {
  return summaries.filter(s => fl.predicate(s)).length > 0
}

const createAllFilters = (currentSearchValue: string): IPRangeFilter[] => {
  if (isIPRange(currentSearchValue)) {
    const { networkPrefix, prefixLength } = parseIPRange(currentSearchValue)

    return [new IPRangeFilter(networkPrefix, prefixLength)]
  } else if (isPartialIP(currentSearchValue)) {
    const expandedIP = expandToIPAddr(currentSearchValue)

    return [new IPRangeFilter(expandedIP, 32 - unusedBits(expandedIP))]
  }

  return []
}

const generateIPRangeFilters = (currentSearchValue: string, summaries: EIPSummary[]): AutoCompleteOption[] =>
  createAllFilters(currentSearchValue).filter(f => ipRangeIsApplicable(f, summaries))

const toAutocompleteOptions = (summaries: EIPSummary[], currentSearchValue: string, searchValues: AutoCompleteOption[]): AutoCompleteOption[] => {
  const options: Map<string, AutoCompleteOption> = new Map<string, AutoCompleteOption>()

  for (const s of summaries) {
    addIfNotPresent(options, new AddressFilter(s.address))

    if (s.nat_gateway) {
      addIfNotPresent(options, new NatGatewayFilter(s.nat_gateway.id))

      if (s.nat_gateway.mta_hostname) {
        addIfNotPresent(options, new MTAHostnameFilter(s.nat_gateway.mta_hostname))
      }

      if (s.nat_gateway.subnet_ids) {
        addIfNotPresent(options, ...(s.nat_gateway.subnet_ids.map(id => new SubnetIDFilter(id))))
      }
    }
  }

  if (!summaries.every(s => (s.nat_gateway?.subnet_ids?.length ?? 0) > 0)) {
    addIfNotPresent(options, new NoSubnetsFilter())
  }

  addIfNotPresent(options, ...generateIPRangeFilters(currentSearchValue, summaries))

  addIfNotPresent(options, ...searchValues)

  return Array.from(options.values()).sort((a, b) => -b.type.localeCompare(a.type))
}

export const EIPTable = (): JSX.Element => {
  const classes = useTableStyles()

  const { eipSummaries, loading, errorMessage } = useEIPTable()

  const {
    filteredEIPSummaries,
    showNonProdEIP,
    filterMenuAnchor,
    ipRepFilter,
    searchValues,
    searchInputValue,
    setSearchInputValue,
    setSearchValues,
    setIPRepFilter,
    openFilterMenu,
    closeFilterMenu,
    handleChangeFilterNonProdEIP
  } = useEIPFilter(eipSummaries)

  const tableData = tableDataFromSummaries(filteredEIPSummaries ?? [])

  const autocompleteOptions = toAutocompleteOptions(filteredEIPSummaries ?? [], searchInputValue, searchValues)

  const {
    sortedTableData,
    updateSorting,
    order,
    orderBy
  } = useSort(tableData)

  return (
    <>
      {loading && (
        <Backdrop open={loading} className={classes.backdrop} >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {!loading && errorMessage === undefined && sortedTableData && (
        <Paper elevation={3}>
          <Toolbar className={classes.toolbar}>
            <Autocomplete
              id='search'
              multiple
              options={autocompleteOptions}
              value={searchValues}
              onChange={(_, newValues) => setSearchValues(newValues)}
              getOptionLabel={(option: AutoCompleteOption) => option.label}
              getOptionSelected={(opt: AutoCompleteOption, val: AutoCompleteOption) => opt.type === val.type && opt.label === val.label}
              groupBy={(option: AutoCompleteOption) => option.type}
              className={classes.searchBar}
              inputValue={searchInputValue}
              onInputChange={(_, newInputValue) => setSearchInputValue(newInputValue)}
              renderInput={(params: AutocompleteRenderInputParams) =>
                <TextField
                  {...params}
                  placeholder="Search..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    )
                  } as InputProps}
                />
              }
            />
            <Button
              aria-label="filter list"
              onClick={openFilterMenu}
              endIcon={<FilterListIcon />}
            >Filters</Button>
            <Menu
              keepMounted
              anchorEl={filterMenuAnchor}
              open={Boolean(filterMenuAnchor)}
              onClose={closeFilterMenu}
            >
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showNonProdEIP} onChange={handleChangeFilterNonProdEIP} />}
                  labelPlacement="start"
                  label="Show non-production EIPs"
                />
              </MenuItem>
              <FormControlLabel
                classes={{
                  label: classes.ipRepFilterLabel
                }}
                control={
                  <Select
                    value={ipRepFilter}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => setIPRepFilter(event.target.value as IPRepFilter)}
                  >
                    <MenuItem value='All' > ✅ + ⚠️ + ❌ </MenuItem>
                    <MenuItem value='Good' > ✅ </MenuItem>
                    <MenuItem value='Bad'> ⚠️ + ❌ </MenuItem>
                  </Select>
                }
                labelPlacement="start"
                label="Show reputation"
              />
            </Menu>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.entries(columns).map(([id, col]) => (
                    <TableCell key={id} align={(col as Column).align ?? 'left'}>
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={(): void => updateSorting(id as keyof Columns)}
                      >
                        <Typography variant="subtitle2">{(col as Column).header}</Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTableData.map((row) => (
                  <TableRow key={row.get('address')?.label}>
                    {Array.from(row.entries()).map(([id, columnCell]) => (
                      <EIPCell
                        key={id}
                        cell={columnCell}
                        align={columns[id].align ?? 'left'}
                        copyable={columnCell?.copyable ?? true}
                      />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {errorMessage}
    </>
  )
}
