import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import Dashboard from '../components/pages/Dashboard'
import EIPDetailsPage from '../components/pages/EIPDetail'
import Landing from '../components/pages/Landing'
import NotFound from '../components/pages/NotFound'

import ProtectedRoute from './ProtectedRoute'
import LoginCallback from '../components/LogInCallback'

const Pages = (): JSX.Element => {
  return (
    <Switch>
      <Route path="/" exact><Landing /></Route>
      <Route path="/login/callback" exact><LoginCallback /></Route>
      <ProtectedRoute path="/eips" exact><Dashboard /></ProtectedRoute>
      <ProtectedRoute path="/eips/:eipID"><EIPDetailsPage /></ProtectedRoute>
      <Route><NotFound /></Route>
    </Switch>
  )
}

export default Pages
