import React from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { useEIPDetail, useEIPEvents } from './Data'
import { EIPEvent, ListedLevel } from '../../eip'

import Link from '@material-ui/core/Link'
import CopyToClipboard from '../CopyToClipboard'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import IconButton from '@material-ui/core/IconButton'
import { toRepIndicator } from '../EipTable/Data'

const useStyles = makeStyles((theme: Theme) => createStyles({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  eipTitle: {
    margin: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  listingText: {
    paddingTop: theme.spacing(1),
    minHeight: theme.spacing(4)
  },
  detailsLine: {
    height: '3rem',
    lineHeight: '3rem'
  }
})
)

interface RouteParams {
  eipID: string;
}

interface Reputation {
  listed: ListedLevel;
  text: string;
}

interface EventRepChange {
  address: string;
  rbl: string;
  old?: Reputation;
  new?: Reputation;
  time: string;
}

const useEventCardStyles = makeStyles((theme: Theme) => createStyles({
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  repChange: {
    marginTop: theme.spacing(2)
  },
  eventCardTitle: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline'
  }
})
)

interface EventCardProps {
  ev: EIPEvent;
}

const EventCard = ({ ev }: EventCardProps): JSX.Element => {
  const classes = useEventCardStyles()

  if (!ev.topic_arn.endsWith('reputation-change')) {
    return (
      <Grid item xs={8}>
        <Card>
          <CardContent>
            <Typography variant="body1">
              <i>Unknown event type...</i>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const repChangePayload = JSON.parse(ev.payload) as EventRepChange

  return (
    <Grid item xs={6}>
      <Card>
        <CardContent>
          <Box className={classes.cardHeader}>
            <Box className={classes.eventCardTitle}>
              <Typography variant="h6">
                {repChangePayload.rbl}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography>
              {new Date(repChangePayload.time).toLocaleString(navigator.language, { timeZoneName: 'short' })}
            </Typography>
            <Typography>
              {new Date(repChangePayload.time).toLocaleString(navigator.language, { timeZone: 'UTC', timeZoneName: 'short' })}
            </Typography>
          </Box>
          <Typography variant="body1" className={classes.repChange}>
            {`${toRepIndicator(repChangePayload.old?.listed)} ${'->'} ${toRepIndicator(repChangePayload.new?.listed)}`}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export const EIPDetails = (): JSX.Element => {
  const params = useParams<RouteParams>()
  const { eipDetail, loading, errorMessage } = useEIPDetail(params.eipID)
  const { eipEvents, loading: eventsLoading, errorMessage: eventsErrorMessage } = useEIPEvents(params.eipID)
  const classes = useStyles()

  return (
    <>
      {loading && (
        <Backdrop open={loading} className={classes.backdrop} >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {!loading && errorMessage === undefined && eipDetail && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.eipTitle}>
              { eipDetail.address + (eipDetail.nat_gateway?.mta_hostname ? ' (' + eipDetail.nat_gateway.mta_hostname + ')' : '') }
            </Typography>
          </Grid>
          <Grid container item justifyContent="flex-start" spacing={2} xs={10}>
            <Grid item xs={12}>
              <Typography variant="h3">
              Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Box className={classes.detailsLine}>
                    <Typography display="inline"><b>Address:</b> { eipDetail.address }</Typography>
                    <CopyToClipboard copyText={eipDetail.address} />
                    <Link href={eipDetail.address_link} target='_blank' rel='noopener noreferrer'>
                      <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                    </Link>
                  </Box>
                  <Box className={classes.detailsLine}>
                    <Typography display="inline"><b>NAT Gateway:</b> { eipDetail.nat_gateway?.id ?? '' }</Typography>
                    { eipDetail.nat_gateway && <CopyToClipboard copyText={eipDetail.nat_gateway.id} /> }
                    { eipDetail.nat_gateway &&
                      <Link href={eipDetail.nat_gateway.link} target='_blank' rel='noopener noreferrer'>
                        <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                      </Link>
                    }
                  </Box>
                  <Box className={classes.detailsLine}>
                    <Typography display="inline"><b>MTA name:</b> { eipDetail.nat_gateway?.mta_hostname ?? '' }</Typography>
                    { eipDetail.nat_gateway?.mta_hostname && <CopyToClipboard copyText={eipDetail.nat_gateway.mta_hostname} /> }
                    { eipDetail.nat_gateway?.mta_link &&
                      <Link href={eipDetail.nat_gateway.mta_link} target='_blank' rel='noopener noreferrer'>
                        <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                      </Link>
                    }
                  </Box>
                  <Box className={classes.detailsLine}>
                    <Typography display="inline"><b>Subnets:</b> { eipDetail.nat_gateway?.subnet_ids?.join(', ') ?? '' }</Typography>
                    { eipDetail.nat_gateway?.subnet_ids?.length === 1 && <CopyToClipboard copyText={eipDetail.nat_gateway.subnet_ids[0]} /> }
                    { eipDetail.nat_gateway?.subnets_link &&
                      <Link href={eipDetail.nat_gateway.subnets_link} target='_blank' rel='noopener noreferrer'>
                        <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                      </Link>
                    }
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container item justifyContent="flex-start" spacing={2} xs={10}>
            <Grid item xs={12}>
              <Typography variant="h3">
              Reputation
              </Typography>
            </Grid>
            {eipDetail.reputations.map(rep =>
              <Grid key={rep.rbl.host} item xs={3}>
                <Card>
                  <CardContent>
                    <Box className={classes.cardHeader}>
                      <Typography variant="h6">
                        {rep.rbl.name ?? rep.rbl.host}
                      </Typography>
                      <Typography>
                        {' ' + toRepIndicator(rep.listed)}
                      </Typography>
                    </Box>
                    <Typography color="textSecondary">
                      {rep.rbl.name && rep.rbl.host}
                    </Typography>
                    <Typography className={classes.listingText}>
                      {rep.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          <Grid item xs={10}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Events (Reputation changes)
                </Typography>
              </Grid>
              {!eventsLoading && eventsErrorMessage === undefined && eipEvents &&
                (eipEvents.length > 0
                  ? <>
                    {eipEvents.map(ev => <EventCard key={ev.message_id} ev={ev} />)}
                  </>
                  : <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <i>There are no events associated with this EIP.</i>
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {eventsErrorMessage}
      {errorMessage}
    </>
  )
}
