import React from 'react'
import EIPTable from '../EipTable'
import Nav from '../Nav'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: theme.spacing(3)
    }
  })
)

export const Dashboard = (): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <Nav />
      <Grid container justify="center" >
        <Grid item xs={8} className={classes.table} >
          <EIPTable />
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
