import React, { useContext } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, useTheme } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import IconButton from '@material-ui/core/IconButton'

import { PillarboxTheme } from '../theme'
import { AuthContext } from '../Auth'

const APP_VERSION = process.env.REACT_APP_VERSION ?? ''

const useStyles = makeStyles(theme => ({
  userCard: {
    display: 'flex',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.grey[700]
  },
  userAvatar: {
    display: 'flex',
    flex: 1,
    maxWidth: '25%',
    pointerEvents: 'none',
    marginRight: theme.spacing(1)
  },
  titleBox: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end'
  },
  versionText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[500]
  },
  toolbar: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.background.paper
  },
  themeToggle: {
    marginRight: theme.spacing(1)
  }
}))

export const Nav = (): JSX.Element => {
  const { profilePicLink, userFullName, email, unauthenticate } = useContext(AuthContext)

  const classes = useStyles()
  const { palette, togglePrefersDarkMode } = useTheme<PillarboxTheme>()

  const logOut = (): void => {
    window.localStorage.setItem('authenticated', 'false')
    window.localStorage.removeItem('id_token')
    window.localStorage.removeItem('jwt_decoded')
    unauthenticate()
  }

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <Box className={classes.titleBox}>
          <Typography variant="h4">
            <Link href="/eips" color="inherit" underline="none">
              EIP Dashboard
            </Link>
          </Typography>

          <Typography variant="caption" className={classes.versionText}>
            {APP_VERSION}
          </Typography>
        </Box>
        <Paper className={classes.userCard}>
          <ButtonBase className={classes.userAvatar}>
            <Avatar src={profilePicLink ?? ''} />
          </ButtonBase>
          <div>
            <Typography variant="subtitle1">
              {userFullName}
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              {email}
            </Typography>
          </div>
        </Paper>
        <IconButton
          className={classes.themeToggle}
          color="inherit"
          onClick={togglePrefersDarkMode}
          aria-label="Toggle light/dark theme"
        >
          {palette.type === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
        <Button
          color="inherit"
          onClick={logOut}>
            Log out
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Nav
