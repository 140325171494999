import React, { useContext, useEffect } from 'react'
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from '../Auth'
import { PassThroughState } from '../components/LogInToGoogle'

const ProtectedRoute = (props: RouteProps): JSX.Element => {
  const { isAuthenticated } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (isAuthenticated === false) {
      history.push({
        pathname: '/',
        state: {
          userRedirect: location.pathname
        } as PassThroughState
      })
    }
  }, [isAuthenticated, history, location.pathname])

  return <Route {...props}/>
}

export default ProtectedRoute
