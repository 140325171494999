import React, { useState } from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import green from '@material-ui/core/colors/green'
import { useIsMounted } from './Mounted'

const ContentCopyIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props}>
    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
  </SvgIcon>

const writeTextToClipboard = (textToCopy: string): Promise<void> => navigator.clipboard.writeText(textToCopy)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      padding: theme.spacing(1)
    },
    doneIcon: {
      color: green[600]
    }
  })
)

interface Props {
    copyText: string;
}

const CopyToClipboard = ({ copyText }: Props): JSX.Element => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const classes = useStyles()
  const isMounted = useIsMounted()

  const handleCopy = async (): Promise<void> => {
    await writeTextToClipboard(copyText)
    setShowConfirmation(true)
    setTimeout(() => {
      if (isMounted.current) setShowConfirmation(false)
    }, 1000)
  }

  return (
    <>
      <IconButton aria-label="copy to clipboard" className={classes.icon} disabled={showConfirmation} onClick={handleCopy}>
        {!showConfirmation
          ? <ContentCopyIcon />
          : <DoneIcon className={classes.doneIcon} />}
      </IconButton>
    </>
  )
}

export default CopyToClipboard
