import React, { useMemo } from 'react'
import { Route, BrowserRouter } from 'react-router-dom'

import { AuthContextProvider } from './Auth'
import Pages from './routes/Pages'
import { useThemePreference, PillarboxThemeOptions } from './theme'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

export const App = (): JSX.Element => {
  const [prefersDarkMode, togglePrefersDarkMode] = useThemePreference()
  const theme = useMemo(() =>
    createMuiTheme({
      palette: {
        type: prefersDarkMode ? 'dark' : 'light'
      },
      togglePrefersDarkMode: togglePrefersDarkMode
    } as PillarboxThemeOptions), [prefersDarkMode, togglePrefersDarkMode])

  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Route component={Pages} />
        </BrowserRouter>
      </ThemeProvider>
    </AuthContextProvider>
  )
}

export default App
