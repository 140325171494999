import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '80vh'
  }
})

const NotFound = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography variant="h1">404: Not Found</Typography>
      <Link href="/">Return to Home</Link>
    </Box>
  )
}

export default NotFound
