import { useHistory } from 'react-router-dom'
import React, { useContext, useEffect, useCallback } from 'react'
import { AuthContext, UserInfo } from '../Auth'
import { PassThroughState } from './LogInToGoogle'

const validateJWTNonce = (decodedJwt: UserInfo): boolean => decodedJwt.nonce === localStorage.getItem('nonce')

export const LoginCallback = (): JSX.Element => {
  const { authenticate } = useContext(AuthContext)
  const history = useHistory()
  const authCallback = useCallback(authenticate, [])

  useEffect(() => {
    (async (): Promise<void> => {
      const res = window.location.href.replace('#', '?')
      const url = new URL(res)
      const token = url.searchParams.get('id_token')
      if (token === null) {
        throw Error("couldn't retreive token from callback URL")
      }

      const response = await fetch('https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=' + token)
      const userInfo = await response.json() as UserInfo
      if (!validateJWTNonce(userInfo)) {
        throw Error('invalid JWT nonce')
      }

      window.localStorage.setItem('authenticated', 'true')
      window.localStorage.setItem('id_token', token)
      window.localStorage.setItem('jwt_decoded', JSON.stringify(userInfo))
      authCallback(token, userInfo)

      const state = url.searchParams.get('state')
      const passThroughState = state !== null ? (JSON.parse(state) as PassThroughState) : undefined

      if (passThroughState?.userRedirect !== undefined) {
        console.log(`Redirecting to ${passThroughState.userRedirect}`)
        history.push(passThroughState.userRedirect)
      } else {
        history.push('/eips')
      }
    })()
      .catch(err => {
        alert(`Log in error: ${(err as Error).toString()}! Please try again.`)
        history.push('/')
      })
      .finally(() => window.localStorage.removeItem('nonce'))
  }, [history, authCallback])

  return <></>
}

export default LoginCallback
